import { Box, ButtonGroup, Grid, IconButton, Typography, Button, Chip } from '@mui/material';
import { Link } from 'react-router-dom';
import CopyrightIcon from '@mui/icons-material/Copyright';
import { links } from '../../utils/data';
import { openInNewTab } from '../../utils/openInNewTab';
import blob from '../../resources/blob2.svg';
import { SignatureImg } from './SignatureImg';

export const Footer = () => (
  <Box
    sx={{
      pt: 20,
      mt: 10,
      pb: 12,
      background: `url(${blob})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }}
  >
    <Grid container direction="row" alignItems="center">
      <Grid xs item container direction="column" justifyContent="center">
        <Grid xs item sx={{ justifyContent: 'center', textAlign: 'center' }}>
          {links.map(({ icon, link }) => (
            <IconButton key={link} onClick={() => openInNewTab(link)} sx={{ color: 'white' }}>
              {icon}
            </IconButton>
          ))}
        </Grid>
      </Grid>
      <Grid xs={12} item container justifyContent="center">
        <Grid xs item sx={{ justifyContent: 'center', textAlign: 'center' }}>
          <SignatureImg sx={{ height: '100px', width: '200px' }} />
        </Grid>
      </Grid>
      <Grid xs={12} item container>
        <Grid xs item sx={{ justifyContent: 'center', textAlign: 'center' }}>
          <Typography variant="h5">Info</Typography>
          <ButtonGroup orientation="vertical" variant="text">
            <Button color="info" component={Link} to="/contact">
              Kontakt
            </Button>
            <Button color="info" component={Link} to="/about">
              Om mig
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid xs item sx={{ justifyContent: 'center', textAlign: 'center' }}>
          <Typography variant="h5">Fönstershoppa</Typography>
          <ButtonGroup orientation="vertical" variant="text">
            <Button color="info" component={Link} to="/gallery">
              Mitt galleri
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Grid item xs sx={{ justifyContent: 'center', textAlign: 'center' }}>
        <Chip icon={<CopyrightIcon color="inherit" />} label=" 2023 StellARtist" />
      </Grid>
    </Grid>
  </Box>
);
