import { ImageListItem, Typography, Zoom } from '@mui/material';
import { Link } from 'react-router-dom';

export const GalleryItem = ({ item, type, itemId }) => {
  const { imgSm, title, price, sold, size } = item;
  const route = `/gallery/${itemId}-${type}`;
  return (
    <Zoom in timeout={1000}>
      <ImageListItem
        component={Link}
        to={route}
        sx={{ cursor: 'pointer', textDecoration: 'none', color: 'primary' }}
      >
        <img
          style={{ '&:hover': { transform: 'scale(1.5)' } }}
          src={`${imgSm}?w=161&fit=crop&auto=format`}
          srcSet={`${imgSm}?w=161&fit=crop&auto=format&dpr=2 2x`}
          alt={title}
          loading="lazy"
        />
        [
        <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {title}
        </Typography>
        {type !== 'poster' && size && <Typography>{`(${size})`}</Typography>}
        {type !== 'poster' && <Typography variant="h5">{sold ? 'SÅLD' : price}</Typography>}]
      </ImageListItem>
    </Zoom>
  );
};
