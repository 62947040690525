import { Box, Grid, IconButton, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import spaceBg from '../../resources/art/spaceBg.jpg';
import { openInNewTab } from '../../utils/openInNewTab';
import { links } from '../../utils/data';

export const Networking = () => (
  <Box
    sx={{
      position: 'relative',
      left: 0,
      width: '100vw',
      height: '30vh',
      background: `url(${spaceBg})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',
    }}
    alignItems="center"
    justifyContent="center"
    display="flex"
  >
    <Grid container justifyContent="center" alignItems="center">
      {links.map(({ text, icon, link }) => {
        return (
          <Grid
            item
            key={text}
            xs={4}
            sm={4}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{ height: '140px', width: '140px' }}
          >
            <Box
              onClick={() => openInNewTab(link)}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                background: 'rgba(0,0,0,0.7)',
                p: 4,
                justifyContent: 'center',
                height: '90%',
                width: '90%',
                cursor: 'pointer',
                transition: 'transform 1s',
                '&:hover': { transform: 'scale(1.3)' },
              }}
            >
              <IconButton sx={{ color: 'white' }}>{icon}</IconButton>
              <Typography align="center" variant="caption">
                {text} <OpenInNewIcon sx={{ fontSize: 'small', mt: -2 }} />
              </Typography>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  </Box>
);
