import {
  Typography as MuiTypography,
  Toolbar,
  Container,
  Box,
  // List,
  // ListItem,
  Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { MyInstagram } from '../components/MyInstagram';
import { LotusBackground } from '../components/LotusBackground';
import sanna from '../../resources/sanna2.jpg';

const Typography = styled(MuiTypography)({ margin: 20 });

export const About = () => (
  <Box>
    <LotusBackground />
    <Container sx={{ textAlign: 'center', pb: 20 }}>
      <Toolbar />
      <Typography variant="h3">Vem är StellARtist?</Typography>
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={6} justifyContent="center">
          <img src={sanna} alt="sanna" style={{ width: '80%', objectFit: 'contain' }} />
        </Grid>
        <Grid item xs>
          <Typography>
            StellARtist är konstnären Susanna Ahlberg. Hon är ett kreativt skapande stjärnfrö som
            finns här för att sprida ljus genom att måla och skapa.
            <br />
            <br />
            Namnet StellARtist är en sammanslagning av orden Stellar och Artist. Stellar är en
            referens för stjärnorna och rakt översatt betyder Stellar artist ungefär stjärnartist.
            Namnet pekar mot Susannas främsta inspiration - vår oändlighet och vårt ursprung, allt
            vi har inom oss, vårt hem, Universum.
          </Typography>
        </Grid>
      </Grid>

      <MyInstagram />
      {/* <Typography sx={{ mt: 18 }} variant="h3">
        Aktuellt
      </Typography> */}
      {/* <Typography align="left">Saker som är på gång/pågående</Typography>
      <List
        sx={{
          listStyleType: 'disc',
          pl: 2,
          '& .MuiListItem-root': {
            display: 'list-item',
          },
        }}
      >
        <ListItem>2022 Höstterminen Körledare i Joyvoice</ListItem>
        <ListItem>
          2023 Juli Del av körensemblen i musikalkonserten <i>Jesus Christ Superstar</i> i Dalhalla
        </ListItem>
      </List>
      <Typography sx={{ mt: 18 }} variant="h3">
        CV
      </Typography>
      <Typography align="left">Urval av sångrelaterade upplevelser</Typography>
      <List
        sx={{
          listStyleType: 'disc',
          pl: 2,
          '& .MuiListItem-root': {
            display: 'list-item',
          },
        }}
      >
        <ListItem>
          2023 Februari StellARtist är en del av huskören i arenaturnen <i>Champions of Rock</i>
        </ListItem>
        <ListItem>2022 November Del av mindre körensemble på välgörenhetskonsert</ListItem>
        <ListItem>
          2022 November Bakgrundssångerska på musikaliska föreläsningen/showen
          <i>Save yourself first</i> i Linköping
        </ListItem>
        <ListItem>
          2022 Juli Del av körensemblen i musikalkonserten <i>Jesus Christ Superstar</i> i Dalhalla
        </ListItem>
        <ListItem>
          2021 December Del av körensemble på Peter Johanssons julkonserter i Linköping och
          Norrköping
        </ListItem>
      </List> */}
    </Container>
  </Box>
);
