import { Paper, Typography, List, ListItem } from '@mui/material';

export const PosterInfo = () => (
  <Paper sx={{ p: 4, mb: 2, maxWidth: '1000px' }}>
    <Typography variant="h5">Posters</Typography>
    <List
      sx={{
        listStyleType: 'disc',
        pl: 2,
        '& .MuiListItem-root': {
          display: 'list-item',
        },
      }}
    >
      <ListItem>
        Fine art print / Giclée på bummulsbaserat papper av högsta kvalitet (Hahnemühle Photo Rag
        308g)
      </ListItem>
      <ListItem>
        Epson Ultrachrome Pro12 pigmentbläck, som är Epsons allra senaste teknik med fantastisk
        färgåtergivning och hållbarhet
      </ListItem>
      <ListItem> Din poster handsigneras av mig på fram och baksida</ListItem>
    </List>
    <Typography>
      Se priser och tillgängliga storekar genom att välja ett motiv i galleriet. För att beställa:
      maila mig namnet på motivet och vilken storlek som önskas. Levereras genom avhämtnig
      alternativt skickas mot porto 49kr. Betalning görs just nu endast genom faktura.
    </Typography>
    <Typography variant="h6">Välkommen att maila din order till kontakt@stellartist.se</Typography>
  </Paper>
);
