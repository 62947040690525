import { useScript } from '../../state/useScript';

export const MyInstagram = () => {
  useScript('https://apps.elfsight.com/p/platform.js');
  return (
    <>
      <script src="https://apps.elfsight.com/p/platform.js" defer />
      <div className="elfsight-app-63b0c902-3d1d-4e83-a854-c1ae27cd47c2" />
    </>
  );
};
