import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  useScrollTrigger,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { SignatureImg } from './SignatureImg';

const pages = [
  { title: 'Start', route: '/' },
  { title: 'Galleri / Shop', route: '/gallery' },
  { title: 'Kontakt', route: '/contact' },
  { title: 'Om mig / CV', route: '/about' },
];

export const Topbar = () => {
  const isScrolled = useScrollTrigger({ threshold: 0, disableHysteresis: true });
  const [anchorEl, setAnchorEl] = useState(undefined);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(undefined);
  };
  return (
    <AppBar
      position="fixed"
      elevation={isScrolled ? 3 : 0}
      sx={{
        width: '100%',
        // top: 0,
        background: isScrolled ? 'rgba(0,0,0,0.5)' : 'transparent',
        backdropFilter: isScrolled && 'blur(10px)',
        transition: 'background 2s',
      }}
    >
      <Toolbar>
        <span style={{ marginTop: 4 }}>
          <Link to="/">
            <SignatureImg sx={{ height: '47px', width: '100px' }} />
          </Link>
        </span>
        <IconButton
          sx={{
            position: 'absolute',
            left: 'calc(50% - 20px)',
            color: isScrolled ? 'white' : 'primary.dark',
            transition: 'color 2s',
          }}
          onClick={handleMenuOpen}
          onMouseEnter={handleMenuOpen}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={handleMenuClose}
          open={!!anchorEl}
          MenuListProps={{ onMouseLeave: handleMenuClose }}
          disableScrollLock
        >
          {pages.map((page) => {
            const { route, title } = page;
            return (
              <MenuItem key={title} component={Link} to={route} onClick={handleMenuClose}>
                <Typography textAlign="center">{title}</Typography>
              </MenuItem>
            );
          })}
        </Menu>
      </Toolbar>
    </AppBar>
  );
};
