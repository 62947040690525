import { Link, Typography } from '@mui/material';
import * as R from 'ramda';
import { Gallery } from '../components/Gallery';
import { galleryData, jewelry } from '../../utils/data';
import { CoverPage } from './CoverPage';
import { Networking } from '../components/Networking';
import { MyInstagram } from '../components/MyInstagram';

const ViewFullGalleryText = () => (
  <Typography align="center" sx={{ mt: -6, mb: 6, cursor: 'pointer' }}>
    <Link href="/gallery">Se hela galleriet</Link>
  </Typography>
);

export const LandingPage = () => {
  return (
    <>
      <CoverPage />
      <Gallery data={R.filter((item) => item.viewOnLandingpage, galleryData)} title="Min konst" />
      <ViewFullGalleryText />
      <Networking />
      <Gallery data={jewelry} title="Smycken" />
      <ViewFullGalleryText />
      <MyInstagram />
    </>
  );
};
