import { AnimateKeyframes } from 'react-simple-animate';
import { Grid, Typography, Button, Toolbar, ListItem, List, Box } from '@mui/material';
import { useParams } from 'react-router-dom';
// import { styled } from '@mui/material/styles';
import * as R from 'ramda';
import SendIcon from '@mui/icons-material/Send';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useEffect, useState } from 'react';
import { galleryData, sizeToPrice, jewelry } from '../../utils/data';

// const Img = styled('img')({
//   margin: 'auto',
//   display: 'block',
//   maxWidth: '100%',
//   maxHeight: '100%',
// });

export const ViewItemPage = () => {
  const { id } = useParams();
  const arrayOfProps = R.split('-', id);
  const isPoster = R.includes('poster', arrayOfProps);
  const isJewelry = R.includes('jewelry', arrayOfProps);
  const isOriginal = R.includes('original', arrayOfProps);
  const content = R.prop(arrayOfProps[0], isJewelry ? jewelry : galleryData);
  const { allImgs, title, description, size, sold, printSizes, price } = content;
  // const img = allImgs[0];

  // const [imageToShow, setImageToShow] = useState(img);
  const [play, setPlay] = useState(true);
  useEffect(() => {
    if (isOriginal) {
      const playing = setInterval(() => {
        setPlay(!play);
      }, 5000);
      return () => {
        clearInterval(playing);
      };
    }
    return undefined;
  }, [play, isOriginal]);

  const prices = R.map((sz) => `${sz} cm: ${R.prop(sz, sizeToPrice)}`, printSizes);
  return (
    <>
      <Toolbar />
      <Grid
        container
        direction="row-reverse"
        justifyContent="center"
        // alignItems="center"
        sx={{ p: 2 }}
      >
        <Grid item xs={12} xl={6} sx={{ textAlign: 'center', mb: 2 }}>
          <Typography variant="h4">{title}</Typography>
          <Typography>{description}</Typography>
          {!sold && isOriginal && (
            <>
              <Typography>{size}</Typography>
              <Button
                sx={{ m: 1 }}
                color="inherit"
                variant="outlined"
                endIcon={
                  <AnimateKeyframes
                    play={play}
                    delay={0}
                    duration={0.15}
                    overlay={3}
                    iterationCount={3}
                    easeType="ease-in-out"
                    keyframes={[
                      'transform: rotate(-10deg)',
                      'transform: rotate(10deg)',
                      'transform: rotate(-10deg)',
                    ]}
                  >
                    <SendIcon sx={{ transform: 'rotate(-40deg)' }} />
                  </AnimateKeyframes>
                }
              >
                Köp detta orginal
              </Button>
            </>
          )}
          {isPoster ? (
            <Box sx={{ pt: 2, pl: 8 }}>
              <Typography variant="h6" sx={{ textAlign: 'left' }}>
                Priser
              </Typography>
              <List
                sx={{
                  listStyleType: 'disc',
                  pl: 2,
                  '& .MuiListItem-root': {
                    display: 'list-item',
                  },
                }}
              >
                {prices.map((p) => {
                  return <ListItem key={p}>{p}</ListItem>;
                })}
              </List>
            </Box>
          ) : (
            <Typography>{sold ? 'SÅLD' : `Pris: ${price}`}</Typography>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{ overflow: 'hidden', mb: 2, display: 'grid', justifyContent: 'center' }}
        >
          <Carousel style={{ maxHeight: '80vh' }}>
            {allImgs.map((item) => (
              <div key={item}>
                <img src={item} alt={title} />
              </div>
            ))}
          </Carousel>
          {/* <Box
            sx={{
              '&:hover': { transform: 'scale(1.5)' },
              transition: 'transform 2s ease-in-out',
              maxHeight: '100%',
              maxWidth: '100%',
            }}
          >
            <Img
              src={`${imageToShow}?w=161&fit=crop&auto=format`}
              srcSet={`${imageToShow}?w=161&fit=crop&auto=format&dpr=2 2x`}
              alt={title}
              loading="lazy"
            />
          </Box>
          <ImageList sx={{ width: 500, height: 250 }} cols={3} rowHeight={164}>
            {allImgs.map((item) => (
              <ImageListItem
                key={item}
                onClick={() => setImageToShow(item)}
                sx={{ cursor: 'pointer' }}
              >
                <img
                  src={`${item}?w=164&h=164&fit=crop&auto=format`}
                  srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  alt={title}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList> */}
        </Grid>
      </Grid>
    </>
  );
};
