import * as React from 'react';
import { styled } from '@mui/material/styles';
import * as R from 'ramda';
import { Typography, Box, Grid, Paper, Container } from '@mui/material';
import { GalleryItem } from './GalleryItem';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export const Gallery = ({ title, data, type }) => {
  return (
    <Container>
      {title && (
        <Typography sx={{ textAlign: 'center', m: 4, mt: 8 }} variant="h2">
          {title}
        </Typography>
      )}
      <Box sx={{ mb: 8 }}>
        <Grid
          container
          justifyContent="center"
          spacing={{ xs: 2, md: 4 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {R.values(
            R.mapObjIndexed((img, itemId) => {
              return (
                <Grid key={img.title} item xs={12} sm={6} lg={4}>
                  <Item>
                    <GalleryItem key={img.title} item={img} type={type} itemId={itemId} />
                  </Item>
                </Grid>
              );
            }, data),
          )}
        </Grid>
      </Box>
    </Container>
  );
};
