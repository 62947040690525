import { Box, Typography, styled } from '@mui/material';
// import sanna from '../../resources/sanna.jpg';
import whaleuniverse from '../../resources/art/whaleuniverseCCsm.jpg';
import { SignatureImg } from '../components/SignatureImg';

const StyledTypography = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  [theme.breakpoints.down('md')]: {
    top: '40%',
  },
}));
export const CoverPage = () => (
  <Box
    sx={{
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      width: '100vw',
      height: '100vh',
      // background: `linear-gradient(90deg, rgba(0,0,0,1) 14%, rgba(0,0,0,0.82) 32%, rgba(255,255,255,0) 66%), url(${sanna})`,
      background: `url(${whaleuniverse})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',
    }}
  >
    <SignatureImg sx={{ marginTop: '10%', width: '70%', height: '30%' }} />
    <StyledTypography align="center" variant="h4" sx={{ position: 'absolute', top: '50%' }}>
      Spirituell konstnär och kreatör
    </StyledTypography>
    {/* <Grid
      sx={{ position: 'absolute', top: '50%', pl: 8, width: '80%' }}
      container
      spacing={{ xs: 6 }}
    >
      <Grid item xs={4} md={2} spacing={4} container direction="column">
        <Grid item xs>
          <Typography align="center" variant="h4">
            Sångerska
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography align="right" variant="h4">
            Kreativ skapare
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={4} spacing={12} container direction="column" justifyContent="flex-start">
        <Grid item xs />
        <Grid item xs>
          <Typography align="center" variant="h4">
            Konstnär
          </Typography>
        </Grid>
        <Grid align="right" item xs>
          <Typography variant="h4">Ljusspridare</Typography>
        </Grid>
      </Grid>
    </Grid> */}
  </Box>
);
