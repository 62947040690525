import InstagramIcon from '@mui/icons-material/Instagram';
import SendIcon from '@mui/icons-material/Send';
import YouTubeIcon from '@mui/icons-material/YouTube';
import peacock from '../resources/art/peacock.png';
import peacockSm from '../resources/art/peacocksmall.jpg';
import explore from '../resources/art/Explore_croppedCC2.jpg';
import exploreSm from '../resources/art/Explore_sm.jpg';
import whaleuniverseCC from '../resources/art/whaleuniverseCC.jpg';
import whaleuniverseCCsm from '../resources/art/whaleuniverseCCsm.jpg';
import Ivinden2 from '../resources/art/Ivinden2.jpg';
import Ivinden2sm from '../resources/art/Ivinden2sm.jpg';
import sleepingfoxcc2 from '../resources/art/sleepingfoxcc2.jpg';
import sleepingfoxcc2sm from '../resources/art/sleepingfoxcc2sm.jpg';
import FoxSkycroppedCC from '../resources/art/FoxSkycroppedCC.jpg';
import FoxSkycroppedCCsm from '../resources/art/FoxSkycroppedCCsm.jpg';
import spriddittljus from '../resources/art/spriddittljus.jpg';
import citrin from '../resources/jewelry/citrin.jpg';
import citrin2 from '../resources/jewelry/citrin2.jpg';
import hanna from '../resources/jewelry/hanna.jpg';
import rosakalcit from '../resources/jewelry/rosakalcit.jpg';
import rosakalcit2 from '../resources/jewelry/rosakalcit2.jpg';
import rosenkvarts from '../resources/jewelry/rosenkvarts.jpg';
import rosenkvarts2 from '../resources/jewelry/rosenkvarts2.jpg';

export const sizeToPrice = {
  // kvadratiska
  '20×20': '180kr',
  '25×25': '250kr',
  '30×30': '350kr',
  '40×40': '500kr',
  '50×50': '700kr',
  // '4x3'
  '40x30': '400kr',
  '54x40': '650kr',
  '67x50': '900kr',

  A4: '200kr',
  A3: '370kr',
};

export const links = [
  {
    icon: <InstagramIcon sx={{ fontSize: '50px' }} />,
    text: 'Instagram',
    link: 'www.instagram.com/_stellartist_',
  },
  {
    icon: <SendIcon sx={{ fontSize: '50px', transform: 'rotate(-40deg)' }} />,
    text: 'Contact me',
    link: 'mailto:kontakt@stellartist.se',
  },
  {
    icon: <YouTubeIcon sx={{ fontSize: '50px' }} />,
    text: 'Youtube',
    link: 'www.youtube.com/stellartist',
  },
];

export const galleryData = {
  spriddittljus: {
    viewOnLandingpage: true,
    allImgs: [spriddittljus],
    imgSm: spriddittljus,
    title: 'Sprid ditt ljus, Ø20cm',
    price: '2000 kr',
    sold: false,
    description: 'Ditt hjärta är en portal',
    printSizes: ['20x20', '25x25', '30x30', '40x40'],
  },
  peackock: {
    viewOnLandingpage: true,
    allImgs: [peacock],
    imgSm: peacockSm,
    title: 'Sprid din magi, 40x55cm',
    price: '5000 kr',
    sold: true,
    description:
      'Det är dags att sprida ditt vackra ljus och minnas den magiska varelse du alltid har varit.',
  },
  fox_sky: {
    viewOnLandingpage: true,
    allImgs: [FoxSkycroppedCC],
    imgSm: FoxSkycroppedCCsm,
    title: 'Låt magin börja',
    size: '24x30cm',
    price: '3000 kr',
    printSizes: ['40x30', '54x40'],
    sold: false,
    description:
      'Din inre kraft har inga begränsningar. Låt inte tankarna styra dig. Var fri i din skaparkraft och låt magin börja.',
  },
  explore: {
    allImgs: [explore],
    viewOnLandingpage: true,
    imgSm: exploreSm,
    title: 'Utforska ditt inre',
    price: '1200 kr',
    printSizes: ['A4'],
    sold: false,
    description: 'Ibland behöver man våga simma ut och lita på att universum har en plan.',
    size: '18x13 cm + vitt inramningspapper',
  },
  whale_universe: {
    allImgs: [whaleuniverseCC],
    viewOnLandingpage: false,
    imgSm: whaleuniverseCCsm,
    title: 'Gränslös',
    price: '1200 kr',
    printSizes: ['A4'],
    sold: false,
    description: 'Som små fragment av en och samma källa, hör vi alla ihop.',
    size: '18x13 cm + vitt inramningspapper',
  },
  sleeping_fox: {
    allImgs: [sleepingfoxcc2],
    imgSm: sleepingfoxcc2sm,
    title: 'Sovande räven',
    price: '2000 kr',
    printSizes: ['A4'],
    sold: false,
    description: 'Målning i tusch',
    size: 'A4 papper',
  },
  i_vinden: {
    allImgs: [Ivinden2],
    imgSm: Ivinden2sm,
    title: 'I vinden',
    price: '3000 kr',
    printSizes: ['40x30', '54x40'],
    sold: false,
    description: '',
  },
  // {
  //     allImgs:
  //     title:
  //     price:
  //     sold:
  // description: '',
  // },
};
export const jewelry = {
  strala: {
    allImgs: [citrin, citrin2],
    imgSm: citrin,
    title: 'Stråla',
    price: '600 kr',
    sold: false,
    description:
      'Den peppiga citrinen hjälper dig vara ditt bästa jag och stråla som den stjärna du är',
  },
  orange: {
    allImgs: [hanna],
    imgSm: hanna,
    title: '',
    price: '600 kr',
    sold: true,
    description: '',
  },
  fridfullt_hjärta: {
    allImgs: [rosakalcit, rosakalcit2],
    imgSm: rosakalcit,
    title: 'Fridfullt hjärta',
    price: '600 kr',
    sold: false,
    description:
      'Attrahera in mer kärlek och känn den helande kraften från den rosa kalciten. Kristallen har en fridfull energi som också påminner om att du är perfekt precis som du är bara genom att existera',
  },
  jag_älskar: {
    allImgs: [rosenkvarts, rosenkvarts2],
    imgSm: rosenkvarts,
    title: 'Jag älskar',
    price: '600 kr',
    sold: true,
    description:
      'Den ultimata kärlekskristallen som också boostar kärlek och även vårdar, lugnar och läker oroliga känslor',
  },
  // {
  //     img:
  //     title:
  //     price:
  //     sold:
  // description: '',
  // },
};
