import { useState } from 'react';
import { Tabs, Tab, Box, Toolbar, Slide, Typography, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as R from 'ramda';
import { galleryData, jewelry } from '../../utils/data';
import { Gallery } from '../components/Gallery';
import lotus from '../../resources/lotus.jpg';
import { PosterInfo } from '../components/PosterInfo';

const StyledBox = styled(Box)(({ theme }) => ({
  padding: 10,
  paddingBottom: 24,
  margin: 10,
  background: `linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.7) 100%),url(${lotus})`,
  backgroundPosition: 'center',
  [theme.breakpoints.up('sm')]: {
    margin: 24,
    padding: 40,
    paddingBottom: 48,
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export const FullGallery = ({ tab }) => {
  const tabToIdx = { originals: 0, posters: 1, jewelry: 2 };
  const [value, setValue] = useState(tabToIdx[tab || 'posters']);

  const handleChange = (event, newValue) => setValue(newValue);

  return (
    <Container align="center">
      <Toolbar />
      <Tabs value={value} onChange={handleChange}>
        <Tab label="Orginalmålningar" />
        <Tab label="Posters" />
        <Tab label="Smycken" />
      </Tabs>
      {/* <FormControlLabel label="Bara tillgängliga" control={<Switch onChange={handleChecked} />} /> */}
      <TabPanel value={value} index={0}>
        <Gallery data={galleryData} type="originals" />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PosterInfo />
        <Gallery data={R.filter((obj) => obj.printSizes, galleryData)} type="poster" />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Gallery data={jewelry} type="jewelry" />
      </TabPanel>
      {value === 2 && (
        <Slide easing="ease-in" timeout={1000} direction="right" in mountOnEnter unmountOnExit>
          <StyledBox>
            <Typography variant="h5" sx={{ textAlign: 'center', mb: 2 }}>
              Hej där!
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'center' }}>
              Vill du beställa ett personligt kristallhalsband med valfri kristall efter dina behov
              och önskemål? Varmt välkommen att kontakta mig så hjälper jag dig:
            </Typography>
          </StyledBox>
        </Slide>
      )}
    </Container>
  );
};
