import { Paper, Typography, Container, Toolbar, Zoom } from '@mui/material';

export const Contact = () => (
  <Zoom in sx={{ transitionDelay: '2000ms' }}>
    <Container align="center">
      <Toolbar />
      <Paper sx={{ p: 8 }}>
        <Typography variant="h5">Vill du handla av mig eller beställa en målning?</Typography>
        <Typography variant="h5">
          Eller är du kanske intresserad av ett personligt kristallhalsband?
        </Typography>
        <Typography sx={{ mt: 4, mb: 4 }}>Maila mig gärna</Typography>
        <Typography variant="h4">kontakt@stellartist.se</Typography>
        <Typography variant="caption"> - StellARtist / Susanna Ahlberg - </Typography>
      </Paper>
    </Container>
  </Zoom>
);
