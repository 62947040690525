import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline/CssBaseline';
import { theme } from '../state/theme';

import { LandingPage } from './pages/LandingPage';
import { Contact } from './pages/Contact';
import { FullGallery } from './pages/FullGallery';
import { ScrollToTop } from './components/ScrollToTop';
import { Topbar } from './components/Topbar';
import { Footer } from './components/Footer';
import { About } from './pages/About';
import { ViewItemPage } from './pages/ViewItemPage';

export const App = () => {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Topbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="contact" element={<Contact />} />
          <Route path="gallery" element={<FullGallery />} />
          <Route path="gallery/:id" element={<ViewItemPage />} />
          <Route path="about" element={<About />} />
        </Routes>
        <Footer />
      </ThemeProvider>
    </BrowserRouter>
  );
};
