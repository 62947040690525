import { Box } from '@mui/material';
import lotus from '../../resources/lotus.jpg';

export const LotusBackground = () => (
  <Box
    sx={{
      position: 'fixed',
      width: '100vw',
      height: '100vh',
      backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.5) 100%),url(${lotus})`,
      backgroundPosition: '80%',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',
      zIndex: -1,
    }}
  />
);
